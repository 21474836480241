<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="6" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายการลูกค้าทั่วไป ({{ rows | numeral("0,0") }})
          </h1>
        </b-col>
        <b-col xl="6" class="text-right d-flex justify-content-end">
          <b-input-group class="panel-input-serach">
            <b-form-input
              class="input-serach"
              placeholder="ค้นหาด้วย อีเมล, ชื่อ, เบอร์โทรศัพท์"
              v-model="filter.Search"
              @keyup="handleSearch"
            ></b-form-input>
            <b-input-group-prepend @click="btnSearch">
              <span class="icon-input m-auto pr-2">
                <font-awesome-icon icon="search" title="View" />
              </span>
            </b-input-group-prepend>
          </b-input-group>
          <b-button v-b-toggle.sidebar-1 class="btn-filter">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline"
              >ค้นหา ({{
                countStatus +
                  countTier +
                  countStartdate +
                  countEnddate +
                  countCashback +
                  countSale +
                  countPoint+
                  countPdpa
              }})</span
            >
          </b-button>
          <b-dropdown class="ml-1 lang-dd border-0" right>
            <b-dropdown-item-button @click="exportData()"
              >ส่งออกข้อมูล</b-dropdown-item-button
            >
          </b-dropdown>
        </b-col>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="ค้นหาแบบละเอียด"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              ล้างค่า
            </button>
          </div>

          <!-- <InputSelect
            class="mb-4"
            title="สถานะ"
            name="status"
            :options="optionsStatus"
            v-model="filter.status"
            @onDataChange="handleChangeStatus"
            valueField="id"
            textField="name"
          /> -->
          <InputSelect
            class="mb-4"
            title="PDPA"
            name="Pdpa"
            :options="optionsPdpa"
            v-model="filter.approveConsent"
            @onDataChange="handleChangePdpa"
            valueField="value"
            textField="text"
          />
          <label class="label-text">วันสมัครสมาชิกเริ่มต้น</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            v-model="filter.StartDate"
          ></datetime>

          <label class="label-text">วันสมัครสมาชิกสิ้นสุด</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            v-model="filter.EndDate"
          ></datetime>

          <div class="text-center">
            <p class="text-danger" v-if="errorDate">
              วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
            </p>
          </div>

          <!-- <b-row>
            <b-col>
              <InputText
                textFloat="คะแนนเริ่มต้น"
                placeholder="คะแนนเริ่มต้น"
                type="text"
                name="pointstart"
                v-model="filter.MinPoint"
              />
            </b-col>
            <span class="lh-60">-</span>
            <b-col>
              <InputText
                textFloat="คะแนนสิ้นสุด"
                placeholder="คะแนนสิ้นสุด"
                type="text"
                name="pointend"
                v-model="filter.MaxPoint"
              />
            </b-col>
          </b-row> -->

          <!-- <b-row>
            <b-col>
              <InputText
                textFloat="ยอดขายเริ่มต้น"
                placeholder="ยอดขายเริ่มต้น"
                type="text"
                name="salestart"
                v-model="filter.MinSale"
              />
            </b-col>
            <span class="lh-60">-</span>
            <b-col>
              <InputText
                textFloat="ยอดขายสิ้นสุด"
                placeholder="ยอดขายสิ้นสุด"
                type="text"
                name="saleend"
                v-model="filter.MaxSale"
              />
            </b-col>
          </b-row>

          <div class="text-center">
            <p class="text-danger" v-if="errorDate">
              วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
            </p>
          </div> -->

          <div class="text-center mt-4">
            <button
              type="button"
              class="btn btn-purple button"
              @click="handleSearch"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </b-sidebar>

      <div class="mt-3 bg-white">
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(createdTime)="data">
                <span>{{
                  new Date(data.item.createdTime) | moment($formatDate)
                }}</span>
              </template>
              <template v-slot:cell(firstname)="data">
                <div class="row no-gutters">
                  <div class="col-12">
                    <div class="review-shortdesc">
                      <p class="font-weight-bold mb-1">
                        {{ data.item.firstname }} {{ data.item.lastname }}
                      </p>
                      <p class="m-0">
                        {{ data.item.telephone }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:cell(point)="data">
                <p class="m-0" v-if="data.item.point === 0">-</p>
                <p class="m-0" v-else>{{ data.item.point | numeral("0,0") }}</p>
              </template>
              <template v-slot:cell(genderId)="data">
                <div v-if="data.item.genderId == 1">ชาย</div>
                <div v-else-if="data.item.genderId == 2">หญิง</div>
                <div v-else class="">N/A</div>
              </template>
              <template v-slot:cell(approveConsent)="data">
                <div v-if="data.item.approveConsent" class="text-success">ยินยอม</div>
                <div v-else class="text-danger">ไม่ยินยอม</div>
              </template>
              <template v-slot:cell(id)="data">
                <div class="d-flex justify-content-center">
                  <b-button variant="link" class="px-1 py-0">
                    <router-link :to="'/member/details/' + data.item.userGUID">
                      ตรวจสอบ
                    </router-link>
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">
                  {{ totalRowMessage }}
                </p>
              </div>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import axios from "axios";
import * as moment from "moment/moment";
import ModalLoading from "@/components/modal/alert/ModalLoading";

export default {
  name: "MemberIndex",
  components: {
    InputText,
    InputSelect,
    ModalLoading
  },
  data() {
    return {
      selected: null,
      options: [{ value: null, text: "สถานะ" }],
      optionsSortBy: [
        { value: 0, text: "มากสุด - น้อยสุด" },
        { value: 1, text: "น้อยสุด - มากสุด" },
      ],
      statusList: [],
      modalMessage: "",
      activeItem: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        {
          key: "createdTime",
          label: "วันที่สมัครสมาชิก",
          class: "w-50px",
        },
        {
          key: "firstname",
          label: "รายละเอียด",
          tdClass: "text-left",
        },
        // {
        //   key: "genderId",
        //   label: "เพศ",
        //   class: "w-50px",
        // },
        {
          key: "email",
          label: "อีเมล",
          class: "w-50px",
        },
        // {
        //   key: "point",
        //   label: "คะแนน",
        //   class: "w-50px",
        // },
        {
          key: "approveConsent",
          label: "PDPA",
          class: "w-100px",
        },
        {
          key: "id",
          label: "",
          class: "w-50px",
        },
      ],
      items: [],
      isBusy: false,
      value: false,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        StartDate: null,
        EndDate: null,
        status: [],
        Status: [],
        Search: "",
        tier: [],
        Tier: [],
        MinPoint: 0,
        MaxPoint: 0,
        MinSale: 0,
        MaxSale: 0,
        MinCashback: 0,
        MaxCashback: 0,
        SortByOrderOrId: 0,
        ApproveConsent: [],
        approveConsent: [],
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      optionsPdpa: [
        { value: [], text: "ทั้งหมด" },
        { value: 0, text: "ไม่ยินยอม" },
        { value: 1, text: "ยินยอม" },
      ],
      totalRowMessage: "",
      isDisable: false,
      checkAll: false,
      selectAllCb: false,
      optionsStatus: [],
      errorDate: false,
      timer: null,
    };
  },
  computed: {
    countTier: function() {
      var count = 0;
      if (this.filter.Tier.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStatus: function() {
      var count = 0;
      if (this.filter.Status.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countPdpa: function() {
      var count = 0;
      if (this.filter.ApproveConsent.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStartdate: function() {
      var count = 0;
      if (this.filter.StartDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countEnddate: function() {
      var count = 0;

      if (this.filter.EndDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countPoint: function() {
      var count = 0;

      if (this.filter.MinPoint != 0 || this.filter.MaxPoint != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countSale: function() {
      var count = 0;

      if (this.filter.MinSale != 0 || this.filter.MaxSale != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countCashback: function() {
      var count = 0;

      if (this.filter.MinCashback != 0 || this.filter.MaxCashback != 0)
        count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
  },
  created: async function() {
    // await this.getMemberList();
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function() {
      this.isBusy = true;

      // if (this.filter.MinPoint == "") this.filter.MinPoint = 0;
      // if (this.filter.MinSale == "") this.filter.MinSale = 0;
      // if (this.filter.MinCashback == "") this.filter.MinCashback = 0;
      // if (this.filter.MaxPoint == "") this.filter.MaxPoint = 0;
      // if (this.filter.MaxSale == "") this.filter.MaxSale = 0;
      // if (this.filter.MaxCashback == "") this.filter.MaxCashback = 0;

      let filter = {...this.filter};
      delete filter.approveConsent;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Member/MemberList`,
        null,
        this.$headers,
        filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        //this.statusList = resData.detail.overviewCount;
      }
      this.isBusy = false;
    },
    getMemberList: async function() {
      let status = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Member/UserMemberApproveStatus`,
        null,
        this.$headers,
        null
      );
      if (status.result == 1) {
        let list = [{ id: [], name: `สถานะ` }];
        let datalist = status.detail.dataList;
        this.optionsStatus = list.concat(datalist);
      }
    },
    onClearFilter() {
      this.filter.PageNo = 1;
      this.filter.PerPage = 10;
      this.filter.StartDate = "";
      this.filter.EndDate = "";
      this.filter.Status = [];
      this.filter.Search = "";
      this.filter.Tier = [];
      this.filter.MinPoint = 0;
      this.filter.MaxPoint = 0;
      this.filter.MinSale = 0;
      this.filter.MaxSale = 0;
      this.filter.MinCashback = 0;
      this.filter.MaxCashback = 0;
      this.filter.ApproveConsent = [];
      this.filter.approveConsent = [];
      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    handleSearch(e) {
      if (
        this.filter.StartDate > this.filter.EndDate &&
        this.filter.EndDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$refs.filterSidebar.hide(true);
        this.filter.PageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },
    handleChangeTier: async function(value) {
      this.filter.Tier = [];
      if (typeof value == "number") {
        this.filter.Tier.push(value);
      }
    },
    handleChangeStatus: async function(value) {
      this.filter.Status = [];
      if (typeof value == "number") {
        this.filter.Status.push(value);
      }
    },
    handleChangePdpa: async function(value) {
      this.filter.ApproveConsent = [];
      if (typeof value == "number") {
        this.filter.ApproveConsent.push(value);
      }
    },
    exportData: async function () {
      this.$refs.modalLoading.show();
      let filter = {...this.filter};
      delete filter.approveConsent;
      axios({
        url: `${this.$baseUrl}/api/Member/Export`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: filter
      })
        .then((response) => {
          this.$refs.modalLoading.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Member-` + dateExcel + `.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.lh-60 {
  line-height: 60px;
}
::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}
::v-deep .lang-dd ul {
  width: 100px;
}
</style>
